var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"panels","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","md":"12"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":"List Data Petunjuk Disposisi"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.listQuery.search),callback:function ($$v) {_vm.$set(_vm.listQuery, "search", $$v)},expression:"listQuery.search"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"blue"},on:{"click":function($event){return _vm.getList()}}},[_vm._v("Search")])],1),_c('v-col',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"red"},on:{"click":function($event){return _vm.showAddForm()}}},on),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Tambah Data ")],1)]}}])},[_c('span',[_vm._v("Klik disini untuk Tambah data baru.")])])],1)],1),_c('v-spacer'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.listData,"items-per-page":15,"server-items-length":_vm.totalData,"loading":_vm.listLoading,"options":_vm.tableOpt},on:{"update:options":function($event){_vm.tableOpt=$event}},scopedSlots:_vm._u([{key:"item.aksi",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"warning","fab":"","x-small":"","to":'/mdata/jukdis/edit/'+item.id}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Ubah")])]),_vm._v("   "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"red","fab":"","x-small":""},on:{"click":function($event){return _vm.delData(item.id)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Hapus Data")])])]}}])})],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.toast.timeout},model:{value:(_vm.toast.show),callback:function ($$v) {_vm.$set(_vm.toast, "show", $$v)},expression:"toast.show"}},[_vm._v(" "+_vm._s(_vm.toast.text)+" "),_c('v-btn',{attrs:{"color":_vm.toast.color,"text":""},on:{"click":function($event){_vm.toast.show = false}}},[_vm._v(" Close ")])],1),_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[_c('v-container',{staticStyle:{"background-color":"rgba(0, 0, 0, 0.8)"},attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-card',{staticClass:"pa-md-2 mx-lg-auto",attrs:{"width":"70vW","outlined":""}},[_c('v-list-item',{attrs:{"three-line":"","centered":""}},[_c('v-list-item-content',[_c('div',{staticClass:"overline mb-4"},[_vm._v("Processing")]),_c('v-list-item-title',{staticClass:"headline mb-1"},[_vm._v("Please Wait....")]),_c('v-list-item-subtitle',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"yellow darken-2"}})],1)],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"350"},model:{value:(_vm.delConfirm),callback:function ($$v) {_vm.delConfirm=$$v},expression:"delConfirm"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Konfirmasi")]),_c('v-card-text',[_vm._v("Apakah anda yakin ingin meng-HAPUS data tersebut?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"warning darken-1","text":""},on:{"click":function($event){_vm.delConfirm = false}}},[_vm._v("Batal")]),_c('v-btn',{attrs:{"color":"blue darken-1"},on:{"click":function($event){return _vm.doDeleteData()}}},[_vm._v("Hapus")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }